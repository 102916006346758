import React from "react";

export default function AlloV1(
  props: React.SVGProps<SVGSVGElement> & { color: string }
) {
  return (
    <svg
      width="76"
      height="17"
      viewBox="0 0 76 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5763 11.2337C12.4114 11.2125 12.3555 11.0031 12.4879 10.9027C14.1498 9.63964 14.652 7.30782 13.581 5.45292L10.4327 0C8.57907 1.07005 7.84822 3.33776 8.65297 5.26152C8.71709 5.4147 8.5639 5.56788 8.41072 5.50377C6.48696 4.6987 4.21863 5.42954 3.14826 7.28319L0 12.7361C1.85143 13.8049 4.17725 13.3065 5.44186 11.6528C5.54261 11.5211 5.75138 11.577 5.77254 11.7415C6.03879 13.8087 7.80495 15.4059 9.94443 15.4059H16.2409C16.2409 13.2664 14.6434 11.4999 12.5763 11.234V11.2337ZM11.5587 11.1989H6.23366C6.01984 11.1989 5.88624 10.9674 5.993 10.7823L8.6555 6.17081C8.76225 5.98573 9.02976 5.98573 9.13652 6.17081L11.799 10.7823C11.9061 10.9674 11.7722 11.1989 11.5584 11.1989H11.5587Z"
        fill={props.color}
      />
      <path
        d="M40.8216 0.210938H38.2188V16.2109H40.8216V0.210938Z"
        fill={props.color}
      />
      <path
        d="M45.6966 0.210938H43.0938V16.2109H45.6966V0.210938Z"
        fill={props.color}
      />
      <path
        d="M57.8943 13.041C58.164 12.389 58.2989 11.6632 58.2989 10.8782C58.2989 10.0931 58.1618 9.36768 57.8943 8.71532C57.6271 8.06553 57.2459 7.50107 56.7648 7.03643C56.2816 6.57147 55.6983 6.20181 55.0337 5.94068C54.3645 5.67986 53.6207 5.54688 52.8213 5.54688C52.0219 5.54688 51.2781 5.68211 50.6111 5.95001C49.9443 6.21791 49.361 6.59046 48.8775 7.05736C48.3964 7.52232 48.0152 8.07937 47.748 8.71499C47.4808 9.35061 47.3438 10.0574 47.3438 10.8115C47.3438 11.6107 47.4808 12.3529 47.748 13.0194C48.0152 13.6834 48.3917 14.2549 48.8683 14.7199C49.3446 15.1849 49.9232 15.5523 50.588 15.8131C51.2573 16.0739 51.9941 16.2069 52.7796 16.2069C53.5651 16.2069 54.3458 16.0742 55.0221 15.8131C55.6935 15.5523 56.2794 15.1845 56.7626 14.7176C57.2436 14.2527 57.6249 13.6885 57.8921 13.0384L57.8943 13.041ZM54.915 13.1431C54.3923 13.7169 53.688 14.0064 52.8213 14.0064C51.9546 14.0064 51.2503 13.7172 50.7276 13.1431C50.2093 12.5764 49.9466 11.8055 49.9466 10.8547C49.9466 9.9038 50.2093 9.19702 50.7276 8.62323C51.2503 8.04234 51.9569 7.74804 52.8238 7.74804C53.6908 7.74804 54.3948 8.04202 54.92 8.62323C55.4383 9.1996 55.7008 9.95597 55.7008 10.8762C55.7008 11.7965 55.4383 12.5741 54.92 13.1408L54.9153 13.1431H54.915Z"
        fill={props.color}
      />
      <path
        d="M31.2778 2.05015L31.0694 1.54456C30.6506 0.529154 29.5966 -0.00797388 28.5866 0.213445L28.3547 0.759677L21.8125 16.1641H24.6639L26.021 12.7612L26.0791 12.6126H32.7812L32.8393 12.7657L34.115 16.1637H37.0942L31.2778 2.05015ZM26.9669 10.3883L27.0996 10.058L28.05 7.68251C28.2755 7.12945 28.4915 6.58095 28.6936 6.05162C28.8936 5.5249 29.0957 4.97639 29.2956 4.42073L29.5139 3.81012L29.7324 4.42073C29.9345 4.9881 30.1345 5.53888 30.3227 6.0539C30.5201 6.59265 30.7178 7.13856 30.9152 7.68934L31.803 10.0625L31.924 10.3883H26.9669Z"
        fill={props.color}
      />
      <path
        d="M67.2428 16.2109H65.1308L62.3468 9.15494H64.3628L66.2348 14.0829H66.2988L68.1548 9.15494H70.0268L67.2428 16.2109ZM70.2561 8.94694V7.63494C71.6641 7.63494 72.3041 7.15494 72.6721 6.09894H74.1441V16.2109H72.1441V8.94694H70.2561Z"
        fill={props.color}
      />
    </svg>
  );
}
